import { template as template_a5116ab14c654ae39eb35a6d1062d313 } from "@ember/template-compiler";
const WelcomeHeader = template_a5116ab14c654ae39eb35a6d1062d313(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
