import { template as template_e0a6dd978cc042b793e261a51e190437 } from "@ember/template-compiler";
const FKLabel = template_e0a6dd978cc042b793e261a51e190437(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
