import { template as template_6e51bd8f676240ec9d409d6817dbbac7 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_6e51bd8f676240ec9d409d6817dbbac7(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
