import { template as template_9c68cf9006dc4522a20bc446358b89e5 } from "@ember/template-compiler";
const FKText = template_9c68cf9006dc4522a20bc446358b89e5(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
